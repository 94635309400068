import React, {useState} from "react";
import axios from "../../axios";
import {showToast} from "../ToastHelper";
import Loader from "../Loader";

function ForwardMail({id, countryCode}) {
    const [showForwardModal, setForwardModal] = useState(false);
    const [forwardEmail, setForwardEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [responseEmail, setResponseEmail] = useState('');
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleForwardModal = () => {
        setForwardModal(!showForwardModal);
        setErrorEmail('');
        setResponseEmail('');
        setForwardEmail('');
    };

    const handleForwardSubmit = async (e) => {
        e.preventDefault();

        if (!forwardEmail.trim()) {
            setErrorEmail('Email field is required.');
            return;
        }

        try {
            setIsMailSent(true);
            setIsLoading(true);
            const response = await axios.post(`email/forward/${id}`, {
                forward_to: forwardEmail,
                country: countryCode
            });

            if (response.data.status === 'success') {
                showToast(response.data.message, 'success');
                toggleForwardModal();
            }
        } catch (error) {
            showToast('Failed to send the email. Please try again.', 'error');
        }finally {
            setIsMailSent(false);
            setIsLoading(false);
        }
    };

    return(
        <>
            {isLoading && <Loader />}
            <button type="button" className="btn btn-sm btn-outline-primary"
                    style={{marginRight: '5px'}} onClick={toggleForwardModal}>
                Forward
            </button>

            {/* Forward Modal */}
            <div className={`modal fade ${showForwardModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: showForwardModal ? 'block' : 'none' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Forward Email</h5>
                            <button type="button" className="close" aria-label="Close" onClick={toggleForwardModal} style={{backgroundColor: 'transparent', border: 'none', fontSize: '22px'}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleForwardSubmit}>
                            <div className="modal-body">
                                <label htmlFor="forwardEmail">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="forwardEmail"
                                    placeholder="Write a email here..."
                                    value={forwardEmail}
                                    onChange={(e) => setForwardEmail(e.target.value)}
                                />
                                {errorEmail && <div className="text-danger mt-2">{errorEmail}</div>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={toggleForwardModal}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {isMailSent ? 'Sending Mail...' : 'Save Changes'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Modal backdrop */}
            {showForwardModal && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default ForwardMail;