import React, {useState} from "react";
import axios from "../../axios";
import {showToast} from "../ToastHelper";
import Loader from "../Loader";
import {useLocation} from "react-router-dom";

function SendToMFMail({id, countryCode}) {
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    const countries = [
        { name: "Brunei", code: "BN" },
        { name: "Cambodia", code: "KH" },
        { name: "Canada", code: "CA" },
        { name: "Costa Rica", code: "CR" },
        { name: "Chile", code: "CL" },
        { name: "Egypt", code: "EG" },
        { name: "España", code: "ES" },
        { name: "Ghana", code: "GH" },
        { name: "Guatemala", code: "GT" },
        { name: "Indonesia", code: "ID" },
        { name: "Malaysia", code: "MY" },
        { name: "Mexico", code: "MX" },
        { name: "Malta", code: "MT" },
        { name: "Portugal", code: "PT" },
        { name: "Peru", code: "PE" },
        { name: "Philippines", code: "PH" },
        { name: "Singapore", code: "SG" },
        { name: "Saudi Arabia", code: "SA" },
        { name: "Thailand", code: "TH" },
        { name: "United Kingdom", code: "GB" },
        { name: "United Arab Emirates", code: "AE" },
    ];

    const handleSendToMF = async (countryShortCode) => {
        try {
            setIsMailSent(true);
            setIsLoading(true);
            const response = await axios.post(`email/sendtomf/${id}`, {
                forward_to_mf: countryShortCode,
                country: countryCode
            });

            if (response.data.status === 'success') {
                showToast(response.data.message, 'success');
            }else {
                showToast(response.data.message, 'error');
            }
        } catch (error) {
            showToast(error.response.data.message, 'error');
        }finally {
            setIsMailSent(false);
            setIsLoading(false);
        }
    };

    return(
        <>
            {isLoading && <Loader/>}
            {location.pathname === `/spain-franchisee/${id}` ? (
                <button type="button" className="btn btn-sm btn-outline-primary" style={{marginRight: '5px'}}
                        onClick={() => handleSendToMF('ES')}>
                    {isMailSent ? 'Sending Mail...' : 'Send To MF'}
                </button>
            ) : location.pathname === `/uk-franchisee/${id}` ? (
                    <button type="button" className="btn btn-sm btn-outline-primary" style={{marginRight: '5px'}}
                            onClick={() => handleSendToMF('UK')}>
                        {isMailSent ? 'Sending Mail...' : 'Send To MF'}
                    </button>
            ) : (
                <div className="dropdown d-inline-block" style={{marginRight: '5px'}}>
                    <button className="btn btn-sm btn-outline-primary dropdown-toggle dropdown-arrow"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {isMailSent ? 'Sending Mail...' : 'Send To MF'}
                    </button>
                    <div className="dropdown-menu">
                        {countries.map((country) => (
                            <button
                                key={country.code}
                                type="button"
                                className="dropdown-item"
                                onClick={() => handleSendToMF(country.code)}
                            >
                                {country.name}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default SendToMFMail;