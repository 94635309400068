import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import {showToast} from "../../components/ToastHelper";

function AquaFranchisee() {
    const [franchiseeList, setFranchiseeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [totalRecords, setTotalRecords] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [first, rows, globalFilter, selectedCountry]);

    const fetchData = () => {
        const currentPage = Math.floor(first / rows) + 1;
        let url = `/get_franchisee?page=${currentPage}&pageSize=${rows}&globalFilter=${globalFilter}&category=aqua`;
        if (selectedCountry) {
            url += `&country_of_interest=${selectedCountry}`;
        }
        axios.get(url)
            .then((response) => {
                setFranchiseeList(response.data.data);
                setTotalRecords(response.data.totalRecords);
            })
            .catch((error) => {
                toast.error('Something went wrong.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onPage = (event) => {
        const newFirst = event.first;
        setFirst(newFirst);
        setRows(event.rows);
    };

    const indexTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                {first + franchiseeList.indexOf(rowData) + 1}
            </React.Fragment>
        );
    };


    const filteredData = franchiseeList.filter((item) =>
        Object.values(item).some(
            (val) =>
                val &&
                val.toString().toLowerCase().includes(globalFilter.toLowerCase())
        )
    );

    const moveToAqua = async (franchise_id, category) => {
        setLoading(true);
        try {
            const response = await axios.post(`franchisee/${franchise_id}/add_to_category`, {
                category: category,
            });
            showToast(response.data.message, 'success');
        }catch (e) {
            showToast('Something went wrong', 'error');
        }finally {
            setLoading(false);
        }
    }

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value); // Update the selected category
        setFirst(0); // Reset pagination when category changes
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <h4 className="card-title mb-5">Franchisee List</h4>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <select className="form-control"
                                                name="country_of_interest"
                                                value={selectedCountry}
                                                onChange={handleCountryChange}>
                                            <option value="">Select Country</option>
                                            <option value="Uk">United Kingdom</option>
                                            <option value="Spain">Spain</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-4 offset-4">
                                        <InputText
                                            placeholder="Search..."
                                            className="form-control"
                                            value={globalFilter}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="table-responsive mt-3">
                                    <DataTable
                                        value={filteredData}
                                        loading={loading}
                                        paginator
                                        rows={rows}
                                        totalRecords={totalRecords}
                                        lazy
                                        onPage={onPage}
                                        first={first}
                                        tableStyle={{ minWidth: '85rem' }}
                                    >
                                        <Column header="#" body={indexTemplate} className="text-center"></Column>
                                        <Column field="first_name" header="First Name" className="text-capitalize"></Column>
                                        <Column field="last_name" header="Last Name" className="text-capitalize"></Column>
                                        <Column field="email" header="Email"></Column>
                                        <Column field="phone_no" header="Phone No"></Column>
                                        <Column field="company_name" header="Company Name" className="text-capitalize"></Column>
                                        <Column field="country_of_interest" header="Country Of Interest" className="text-capitalize"></Column>
                                        <Column field="main_interest" header="Main Interest" className="text-capitalize"></Column>
                                        <Column field="formatted_created_at" header="Date" className="text-capitalize"></Column>
                                        <Column header="Action" body={(rowData) => (
                                            <React.Fragment>
                                                <Link to={`/franchisee/${rowData.id}`} className="btn btn-light btn-sm" style={{marginRight: '6px'}}>View</Link>
                                                {rowData.email_status === 'sent_to_mf' ? (
                                                    <span className="badge badge-soft-primary">Send To MF</span>
                                                ) : rowData.email_status === 'sorry_sent' ? (
                                                    <span className="badge badge-soft-danger">Sorry</span>
                                                ) : rowData.email_status === 'thank_you_sent' ? (
                                                    <span className="badge badge-soft-success">Thank You</span>
                                                ) : rowData.email_status === 'forwarded' ? (
                                                    <span className="badge badge-soft-info">Forwarded</span>
                                                ) : rowData.email_status === 'custom_message_sent' ? (
                                                    <span className="badge badge-soft-primary">Customized</span>
                                                ) : (
                                                    <span className="badge badge-soft-warning">Unread</span>
                                                )}
                                            </React.Fragment>
                                        )}></Column>
                                        <Column header="Gold/Aqua" body={(rowData) => (
                                            <React.Fragment>
                                                <button type="button" className="btn btn-dark btn-sm mb-2"
                                                        onClick={() => moveToAqua(rowData.id, "none")}>Remove from Aqua</button>
                                        </React.Fragment>
                                    )}></Column>
                            </DataTable>
                            {loading && <Loader/>}
                            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AquaFranchisee;
