import React, {useState} from "react";
import axios from "../../axios";
import {showToast} from "../ToastHelper";
import Loader from "../Loader";

function ThankYouMail({id, countryCode}) {
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const thankYouMailFn = async () => {
        setIsMailSent(true);
        setIsLoading(true);
        try {
            const response = await axios.post(`email/thankyou/${id}`,{
                country: countryCode
            });
            showToast(response.data.message, 'success');
        }catch (e) {
            showToast('Something went wrong', 'error');
        }finally {
            setIsMailSent(false);
            setIsLoading(false);
        }
    }

    return(
        <>
            {isLoading && <Loader />}
            <button type="button" className="btn btn-sm btn-outline-primary" style={{marginRight: '5px'}}
                    onClick={thankYouMailFn}>
                {isMailSent ? 'Sending Mail...' : 'Thank You'}
            </button>
        </>
    )
}

export default ThankYouMail;